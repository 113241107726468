$celoBlue: #49a9fc; // #doger-blue
$celoBlueEnterprise: #1736bf; // #doger-blue
$celoBlueLight: #49a8fc94; // #doger-blue
$celoBlueBackground: rgba(73, 169, 252, 0.1);
$celoBlueDark: #266ba8; // #doger-blue
$celoSecondary: #ff1d5e; // yellowish-Secondary
$celoSecondaryLighter: #ff3971; // yellowish-Secondary
$celoSecondaryLight: #ffe8ee; // yellowish-Secondary
$celoGreen: rgb(15, 185, 15); // yellowish-orange
$celoGreySelected: #ecf6ff; // pale-grey
$celoBrownGrey: #929292; // brown-grey
$celoBlueGrey: #acaeb1; // light-blue-grey
$celoBlueWhite: rgb(243, 249, 255); // light-blue-grey
$celoLightBlueGrey: #d1d1d6; // light-blue-grey
$celoGreyishBrown: #434343; // greyish-brown
$celoDarkGrey: #6d7278;
$celoLightGrey: #f0f0f0;
$celoRed: #E02020;
$celoRed10: #ff0000; // red-10
$celoBlack70: rgba(0,0,0,0.7); // black-70
$celoBlack54: rgba(0,0,0,0.54); //black-54

$sidebarWidth: 360px;
$headerHeight: 76px;
$nav-height: 60px;

$top-pad: 1em;
$content-height-padded: calc(100vh - #{$nav-height});
$content-height: calc(100vh - #{$nav-height});
$content-height-message-detail: calc(100vh - #{$nav-height} - #{$headerHeight});
