@import 'variables';


body{
    margin: 0px;
    padding: 0px;
    background-color: $celoBlueBackground;
    min-width: 1100px;
}

html, body {
    font-family: "Roboto", sans-serif;
}
// .parent{
//   min-height: 70vh;
// }
.celo-page{
    max-width: 1600px;
    margin-left: auto;
    margin-right: auto;
}
.celo-break-word{
  /* These are technically the same, but use both */
  overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-word-break: break-all;
  /* This is the dangerous one in WebKit, as it breaks things wherever */
  word-break: break-all;
  /* Instead use this non-standard one: */
  word-break: break-word;
  /* Adds a hyphen where the word breaks, if supported */
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
}
.celo-content{
  background-color: white;
  padding: 20px;
  box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.14);
  border-radius: 1px;
}
.mih_100vh{
  min-height: 100vh;
}
.page-title{
  font-size: 26px;
  margin-bottom: 20px;
}
.celo-link{
    text-decoration: none;
    color: $celoBlueDark!important;
}
.celo-link:hover{
    text-decoration: underline;
}

.celo-cursor-pointer{
    cursor: pointer;
}
.relative{
  position: relative;
}
.absolute{
  position: absolute;
}

.celo-hoverable{
  background-color: rgba(0, 0, 0, 0.0)!important;
  transition: background-color 0.4s ease-in-out;
}
.celo-hoverable:hover{
  // background-color: rgba(0, 0, 0, 0.04)!important;
  background-color: rgba(255, 29, 94, 0.04)!important;
}

.celo-hoverable-light{
  background-color: rgba(255, 255, 255, 0.0)!important;
  transition: background-color 0.4s ease-in-out;
}
.celo-hoverable-light:hover{
  background-color: rgba(255, 255, 255, 0.1)!important;
}
.breadcrums{
  line-height: 30px;
  a{
    text-decoration: none;
    color: $celoBlue;
  }
}

.appear_anim_0{
  animation: 0.1s ease-out 0s 1 appear_transition;
}
.appear_anim_1{
  animation: 0.3s ease-out 0s 1 appear_transition;
}
.appear_anim_2{
  animation: 0.6s ease-out 0s 1 appear_transition;
}
.appear_anim_3{
  animation: 0.9s ease-out 0s 1 appear_transition;
}
.appear_anim_4{
  animation: 1.2s ease-out 0s 1 appear_transition;
}
.appear_anim_5{
  animation: 1.4s ease-out 0s 1 appear_transition;
}
.fh{
  height: 100%;
}
.fw{
  width: 100%;
}
.red{
  background: red;
}
.blue{
  background: blue;
}
.green{
  background: green;
}

.celo-input{
  height: 30px;
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  border-color: rgba(0, 0, 0, 0.2)!important;
  width: 100%;
  max-width: 300px;
  padding-left: 5px;
  box-shadow:inset 0px 0px 0px rgba(0, 0, 0, 0.0);
  transition: box-shadow 0.5s ease;
}

.celo-input:hover{
  box-shadow:inset 0px 0px 4px $celoBlue;
}
.celo-input:hover::placeholder{
  color: rgba(0, 0, 0, 0.6);
}

.celo-input::placeholder{
  padding-left: 5px;
  color: rgba(0, 0, 0, 0.45);
}

.celo-input:focus {
  outline-width: 0;
}
.celo-button{
        i{
            font-size: 16px;
            margin-right: 5px;
        }
}
.celo-button:disabled{
  opacity: 0.6;
}
.celo-primary-button{
  background-color: $celoSecondary!important;
  color: white!important;
  min-width: 100px!important;
  border-radius: 7px!important;
  box-shadow: none;
  transition: box-shadow 0.5s ease-in-out;
  outline: none;
  border: none;
  text-align: center;
  height: 40px;
  letter-spacing: 0.7px;
  font-size: 12px;
  text-transform: uppercase;
}
.celo-primary-button:hover{
  box-shadow: 2px 2px 3px rgba(145, 145, 145, 0.5);
}
.celo-primary-button:disabled{
  opacity: 0.4;
}
.celo-secondary-button{
  color: $celoSecondary!important;
  background-color: $celoSecondaryLight!important;
  min-width: 100px!important;
  border-radius: 7px!important;
  box-shadow: none;
  transition: box-shadow 0.5s ease-in-out;
  outline: none;
  border: none;
  text-align: center;
  height: 40px;

  letter-spacing: 0.7px;
  font-size: 12px;
  text-transform: uppercase;
}
.celo-secondary-button:disabled{
  opacity: 0.4;
}
.celo-secondary-button:hover{
  box-shadow: 2px 2px 3px rgba(145, 145, 145, 0.2);
}
.celo-secondary-button-o{
  color: $celoSecondary!important;
  background-color: white!important;
  min-width: 100px!important;
  border-radius: 4px!important;
  border-style: solid!important;
  border-width: 1px!important;
  border-color: $celoSecondary!important;
  transition: background-color 0.2s ease-in-out;
}
.celo-secondary-button-o:hover{
  background-color: rgb(244, 244, 244)!important;
}
.celo-tertiary-button{
  color: $celoBlue!important;
  min-width: 60px!important;
  border-radius: 7px!important;
  outline: none;
  border: none;
  text-align: center;
  font-size: 14px;
  font-weight: 400!important;

  span{
    font-size: 14px;
    font-weight: 400!important;
    padding-left:5px;
    padding-right:5px;
  };
  i{
    color: $celoBlue!important;
  }
}
.celo-tertiary-button:disabled{
  opacity: 0.4;
}

.celo-action-button{
  min-width: 40px;
  i{
    color: grey;
    font-size: 20px;
  }
}
.celo-link{
  opacity: 1.0;
  transition: opacity 0.6s ease;
  cursor: pointer;
  text-decoration: underline;
}
.celo-link:hover{
  opacity: 0.8;
}
.celo-primary-link{
  color: $celoBlue;
}
.celo-red-link{
  color: $celoRed;
}

.celo-red-button-shade{
  min-width: 80px;
  background-color: rgba(0, 0, 0, 0.02);
  color: rgba(0, 0, 0, 0.7);
  transition: all 0.6s ease;
}
// .celo-red-button-shade:disabled{
//   opacity: 0.4;
// }
.celo-red-button-shade:hover{
  background-color: $celoRed;
  color: white;
}
.celo-red-button{
  min-width: 80px;
  background-color: $celoRed!important;
  color: white!important;
  min-width: 100px!important;
  border-radius: 4px!important;

  border-radius: 4px!important;
  border-style: solid!important;
  border-width: 1px!important;
  border-color: $celoRed!important;
}
.celo-primary-icon-button{
  background-color: rgba(0, 0, 0, 0.05)!important;
  color: $celoBlue!important;
}

@keyframes appear_transition {
  0% {
    transform: translateY(20px);
    opacity: 0.0;
  }
  50% {
    transform: translateY(5px);
    opacity: 0.5;
  }
  100% {
    transform: translateY(0px);
    opacity: 1.0;
  }
}
.alert-panel {
  mat-dialog-container{
    border-radius: 10px;
    max-width: 600px !important;
    max-height: 95vh;
    overflow: auto;
    font-size: 12px;
    // padding: 5%;
  }
  .content{
    padding: 1%;
    .action{
      margin-top: 20px;
    }
  }
  .title{
    font-size: 18px;
    font-weight: 500;
  }
  .subtitle{
    font-size: 14px;
    color: $celoDarkGrey;
  }
}
.celo-popup {
  mat-dialog-container{
    border-radius: 10px;
    min-width: 500px !important;
    max-width: 600px !important;
    max-height: 95vh;
    overflow: auto;
    font-size: 12px;
  }
  .content{
    padding: 1%;
    .action{
      margin-top: 20px;
    }
  }
  .title{
    font-size: 18px;
    font-weight: 500;
  }
  .subtitle{
    font-size: 14px;
    color: $celoDarkGrey;
  }
  
}

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }


.relative-table-container {
  position: relative;
  min-height: 200px;
}

.table-container {
  position: relative;
  overflow: auto;
  min-height: 500px;
  background-color: rgba(0, 0, 0, 0.01);
}

.table {
  width: 100%;
  .value-field{
    max-width: 150px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}
.celo-elipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.celo-elipsis-2 {
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}



.table-header{
  background: $celoBlueBackground;
}
.header-align-right{
  display: flex;
  justify-content: flex-end;
}

.loading-shade {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 56px;
  right: 0;
  // background: rgba(0, 0, 0, 0.15);
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.rate-limit-reached {
  color: #980000;
  max-width: 360px;
  text-align: center;
}

/* Column Widths */
.mat-column-number,
.mat-column-state {
  max-width: 64px;
}

.mat-column-created {
  max-width: 124px;
}

.inline_block{
  display: inline-block;
}

.celo-danger-button{
  color: $celoBlue!important;
  min-width: 60px!important;
  outline: none;
  border: none;
  text-align: center;
  font-size: 14px;
  font-weight: 400!important;
  color: $celoRed!important;
  min-width: 100px!important;

  span{
    font-size: 14px;
    font-weight: 400!important;
    padding-left:5px;
    padding-right:5px;
  };
  i{
    color: $celoBlue!important;
  }
}